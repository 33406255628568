import { isDefined } from "@clipboard-health/util-ts";
import { IonPage } from "@ionic/react";
import { Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { useGetFacilitiesExclusions } from "@src/appV2/Facilities/api/useGetFacilitiesExclusions";
import { APP_V2_USER_EVENTS, AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import {
  useGetAgentActiveStreaks,
  useGetAgentStreaksBonus,
  useGetAgentStreaksProgress,
  useStreaksEnabledGlobally,
} from "@src/appV2/Streaks/api";
import { ShiftBlockStreakAlertWrapper } from "@src/appV2/Streaks/components/ShiftBlockStreakAlertWrapper";
import { StreakBonusTrackerList } from "@src/appV2/Streaks/components/StreakBonusTrackerList";
import { StreakInstructions } from "@src/appV2/Streaks/components/StreakInstructions";
import { StreakProgressList } from "@src/appV2/Streaks/components/StreakProgressList";
import { AgentStreakProgressState } from "@src/appV2/Streaks/types";
import { useGetStreaksSegmentData } from "@src/appV2/Streaks/useGetStreaksSegmentData";
import { getPayBoostActiveStreaks } from "@src/appV2/Streaks/utils";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { isEmpty, sortBy, uniq } from "lodash";
import { generatePath, useHistory } from "react-router-dom";

export function StreakTrackerPage() {
  const worker = useDefinedWorker();

  const { userId } = worker;

  const history = useHistory();

  const segmentMetadata = useGetStreaksSegmentData(userId, worker);
  const shouldLogMetadata = isDefined(segmentMetadata) && !isEmpty(userId);
  useLogEffect(
    shouldLogMetadata ? APP_V2_USER_EVENTS.VIEWED_STREAK_TRACKER_PAGE : undefined,
    segmentMetadata
  );

  const isStreaksEnabledGlobally = useStreaksEnabledGlobally();

  if (!isStreaksEnabledGlobally) {
    history.replace(DeprecatedGlobalAppV1Paths.OPEN_SHIFTS);
  }

  const {
    data: activeStreaksData,
    isLoading: isLoadingActiveStreaks,
    refetch: refetchActiveStreaks,
  } = useGetAgentActiveStreaks(
    {
      agentId: userId,
      filter: { date: new Date() },
    },
    { enabled: !isEmpty(userId) && isStreaksEnabledGlobally }
  );

  const {
    data: streaksProgressData,
    isLoading: isLoadingStreaksProgress,
    refetch: refetchStreaksProgress,
  } = useGetAgentStreaksProgress(
    {
      agentId: userId,
      filter: { date: new Date() },
    },
    { enabled: !isEmpty(userId) && isStreaksEnabledGlobally }
  );

  const {
    data: streaksBonusData,
    isLoading: isLoadingStreaksBonus,
    refetch: refetchStreaksBonus,
  } = useGetAgentStreaksBonus(
    {
      agentId: userId,
      filter: { date: new Date() },
    },
    { enabled: !isEmpty(userId) && isStreaksEnabledGlobally }
  );

  const totalBonusPay = streaksBonusData?.data?.attributes?.totalBonusPay;

  const exclusionQueries = useGetFacilitiesExclusions(
    {
      agentId: userId,
      facilityIds: uniq([
        ...(activeStreaksData?.data.map((streak) => streak.attributes.facilityId) ?? []),
        ...(streaksProgressData?.data.map((progress) => progress.attributes.facilityId) ?? []),
      ]),
    },
    {
      enabled:
        !isEmpty(userId) &&
        (isDefined(activeStreaksData) || isDefined(streaksProgressData)) &&
        isStreaksEnabledGlobally,
    }
  );

  const isLoadingExclusions = exclusionQueries.some((query) => query.isLoading);
  const refetchExclusions = async () =>
    await Promise.all(exclusionQueries.map(async (query) => await query.refetch()));

  const dnrFacilities = new Set(
    exclusionQueries
      .flatMap((query) => query.data?.data)
      .map((exclusion) => exclusion?.facility.userId)
      .filter((id) => isDefined(id))
  );

  // organizes it so that the streak with the most progress is at the top.
  const sortedStreaksProgress = sortBy(streaksProgressData?.data, (streak) =>
    streak.attributes.status === AgentStreakProgressState.NOT_ENABLED_AT_FACILITY
      ? Number.MAX_VALUE // this puts all facilities that are not enabled at the end
      : streak.attributes.minHoursForActiveStreak - streak.attributes.verifiedHoursInStreakPeriod
  ).filter((progress) => {
    return !dnrFacilities.has(progress.attributes.facilityId);
  });

  const activeStreaks = activeStreaksData?.data?.filter((streak) => {
    return !dnrFacilities.has(streak.attributes.facilityId);
  });
  const payBoostActiveStreaks = getPayBoostActiveStreaks(activeStreaks);

  async function refreshEverything() {
    if (isStreaksEnabledGlobally) {
      await refetchActiveStreaks();
      await refetchStreaksProgress();
      await refetchStreaksBonus();
      await refetchExclusions();
    }
  }

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader title="Streaks" leftCta={<BackButtonLink defaultBackTo="home/ratings" />} />
        }
      >
        <PullToRefresh
          onRefresh={async () => {
            await refreshEverything();
          }}
        />
        {/* TODO: Add a Redirect page to send user away they should not be here https://linear.app/clipboardhealth/issue/CS-2983/[draft]-redirect-hcp-to-calendar-if-they-should-not-be-seeing-the */}
        {isStreaksEnabledGlobally && (
          <Stack spacing={2}>
            <Stack direction="row" alignItems="end" justifyContent="flex-start">
              <ShiftBlockStreakAlertWrapper worker={worker} />
            </Stack>
            <StreakBonusTrackerList
              totalBonusPay={totalBonusPay ?? 0}
              activeStreaks={payBoostActiveStreaks}
              isLoading={isLoadingExclusions || isLoadingActiveStreaks || isLoadingStreaksBonus}
              onFacilityClick={(facilityId) => {
                history.push(
                  generatePath(DeprecatedGlobalAppV1Paths.FACILITY_DETAIL, {
                    facilityId,
                  })
                );
              }}
            />
            <StreakProgressList
              streakProgressList={sortedStreaksProgress}
              isLoading={isLoadingExclusions || isLoadingStreaksProgress}
              clickOnFacility={(facilityId) => {
                history.push(
                  generatePath(DeprecatedGlobalAppV1Paths.FACILITY_DETAIL, {
                    facilityId,
                  })
                );
              }}
            />
            <StreakInstructions />
          </Stack>
        )}
      </PageWithHeader>
    </IonPage>
  );
}
